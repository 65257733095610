import React, { useContext } from "react"
import { UserContext } from "../context/userContext"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import ToggleMute from "../components/ToggleMute"

export default function JobApplicationStart() {
  const { user, saveUser } = useContext(UserContext)
  return (
    <Background>
      <div className="d-flex flex-column align-items-center">
        <h1 className="display-3 font-weight-bold ml-4 mt-4">
          Job Application
        </h1>

        <h1 className="display-5 font-weight-bold mt-4 p-4">
          Please be informed that the whole
          <br />
          job application process will be recorded
          <br />
          for file record purpose.
        </h1>

        <h1 className="display-5 font-weight-bold mt-4 p-4">
          Please click I Agree to Proceed
        </h1>

        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>

        <div className="p-4 d-flex">
          <Button size="medium" text="Back" onClick={() => navigate("/menu")} />
          <Button
            text="I Agree"
            size="medium"
            onClick={() => navigate("/job-application-check")}
          />
        </div>
      </div>
    </Background>
  )
}
